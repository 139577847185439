.App {
  text-align: center;

  background: #5433ff; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to top,
    #a5fecb,
    #20bdff,
    #5433ff
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to top,
    #a5fecb,
    #20bdff,
    #5433ff
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  min-height: 100vh;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  /* color: rgb(0, 0, 0); */
  font-family: Arial, Helvetica, sans-serif;
}

#about h1 {
  font-size: 3rem;
}

#about h2 {
  font-size: 2rem;
  font-weight: normal;
  margin-bottom: 2em;
}

#profile {
  margin-top: 1em;
  /* margin-left: auto;
  margin-right: auto; */
  max-width: 70%;
  height: auto;
  border-radius: 50%;
  border: solid;
  border-color: white;
  /* border-style: double; */
  border-width: 5px;
  transition: all 0.4s ease-in-out;
}

#profile:hover {
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
  border-radius: 10%;

  border-color: transparent;
}

.social-links {
  /* display: flex;

  justify-content: space-between;
  max-width: 250px;
  margin: auto; */
  margin-bottom: 4em;
}

.social-links a {
  padding: 1rem;
}
